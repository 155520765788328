import { useNavigate } from 'react-router-dom';

import { Card } from '@/components/Card';
import { CardProps } from '@/components/Card/CardProps';
import { businessInfo, scrapUrl } from '@/signals';

export const ModeSelector = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    return navigate('/upload');
  };

  const handleOnChange: CardProps['onChange'] = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'businessInfo') {
      businessInfo.value = value;
    }

    if (name === 'scrapUrl') {
      scrapUrl.value = value;
    }
  };

  return (
    <div className="min-h-screen flex-center">
      <div className="bg-white flex flex-col gap-6 rounded-lg shadow-lg px-4 pb-10 pt-12">
        <h1 className="font-bold !text-2xl flex items-center text-center justify-center">
          Construye la conversación perfecta para tu chatbot
        </h1>
        <div className="flex min-h-[301px]">
          <Card
            title="En base a la info de tu página web"
            text="Nuestra IA tomará la información de tu sitio web para entender sobre tu negocio y sugerir las preguntas para tu conversación."
            textButton="Crea tu conversación"
            type="web"
            onClick={handleClick}
            onChange={handleOnChange}
            className="ml-auto py-6 pl-3"
            value={scrapUrl.value}
            disabled={businessInfo.value.length > 0}
          />
          <div className="w-[1px] bg-gray-400 mx-8 py-8" />
          <Card
            disabled={scrapUrl.value.length > 0}
            className="pr-4 py-6"
            title="En base a información de tu negocio"
            text="Incluye información que nos ayude a entender sobre tu negocio, los horarios de atención, productos y servicios, etc."
            textButton="Crea tu conversación"
            type="link"
            onClick={handleClick}
            onChange={handleOnChange}
            value={businessInfo.value}
          />
        </div>
      </div>
    </div>
  );
};
