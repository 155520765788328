export const SendIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_323_1988)">
        <path
          d="M2.4952 3.3263L6.1917 4.91121L2.49028 4.419L2.4952 3.3263ZM6.18678 7.61837L2.49028 9.20329V8.11058L6.18678 7.61837ZM1.51078 1.8349L1.50586 5.28037L8.88901 6.26479L1.50586 7.24921L1.51078 10.6947L11.8423 6.26479L1.51078 1.8349Z"
          fill="#CDCDCD"
        />
      </g>
      <defs>
        <clipPath id="clip0_323_1988">
          <rect
            width="11.813"
            height="11.813"
            fill="white"
            transform="translate(0.521484 0.358276)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
