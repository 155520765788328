export const WidgetMessageExample = (props) => {
  const { Icon, message } = props;

  return (
    <div className="w-63 h-14 bg-gray-100 shadow-md flex-center gap-4 px-4">
      <Icon />
      <p className="text-gray-700 text-xs flex items-center w-44">{message}</p>
    </div>
  );
};
