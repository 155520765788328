import { Button } from 'cliengo-ds';
import { useCopyToClipboard } from 'react-use';
import { useEffect, useState } from 'react';

import { IconCopy } from './IconCopy';
import { IconCopyCheck } from './IconCopyCheck';

const codeSnippet = `
  <!-- Código de instalación Cliengo para 'www.dddd.com', -->
  <script type="text/javascript">(function () {
      var ldk = document.createElement('script');
      ldk.type = 'text/javascript';
      ldk.async = true;
      ldk.src = 'https://s.stagecliengo.com/weboptimizer/6452dae8061dfc0032a1ee66/645d02d1ec7465005d0126af.js?platform=onboarding_modular';
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(ldk, s);
  })();</script>
  `;

export const InstallationCard = () => {
  const [, copyToClipboard] = useCopyToClipboard();
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowButton(false);
    }, 5000);
  }, [showButton]);

  const handleClick = () => {
    copyToClipboard(codeSnippet);
    setShowButton(true);
  };

  const handleButtonTutorial = () => {
    window.open('https://www.youtube.com/watch?v=qOhs6PPJ56M', '_blank');
  };

  return (
    <div className="flex-col border-gray-300 border-2 p-3 rounded-md flex gap-4">
      <ul className="flex-col flex gap-3">
        <li className="flex items-center gap-2">
          <span className="text-primary text-sm">Paso 1-</span>
          <p className="text-sm text-gray-800">
            Copia el código script de instalación{' '}
          </p>
        </li>
        <li className="flex items-center gap-2">
          <span className="text-primary text-sm">Paso 2-</span>
          <p className="text-sm text-gray-800">
            Dirigeté al código html de tu sitio
          </p>
        </li>
        <li className="flex items-center gap-2">
          <span className="text-primary text-sm">Paso 3-</span>
          <p className="text-sm text-gray-800">
            Pega el código script en la sección header del html.
          </p>
        </li>
      </ul>
      <pre className="w-[676px] border pl-2 border-gray-500 rounded-md bg-gray-200 overflow-auto text-gray-700">
        <code>{codeSnippet}</code>
      </pre>

      <div className="flex justify-end gap-5">
        <Button variant="primary" mode="outline" onClick={handleButtonTutorial}>
          Ver video tutorial
        </Button>

        {showButton ? (
          <Button variant="primary">
            <span className="mr-1">
              <IconCopyCheck />
            </span>
            Copiado
          </Button>
        ) : (
          <Button variant="primary" onClick={handleClick}>
            <span className="mr-1">
              <IconCopy />
            </span>
            Copiar
          </Button>
        )}
      </div>
    </div>
  );
};
