import { createBrowserRouter } from 'react-router-dom';

import { MainView } from './views/MainView/index';
import { ModeSelector } from './views/ModeSelector/ModeSelector';
import { TestConversation } from './views/TestConversation/TestConversation';
import { InstallChatbot } from './views/InstallChatbot';
import { UploadView } from './views/Upload ';
import { Installation } from './views/Installation/Installation';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <MainView />,
  },
  {
    path: '/mode',
    element: <ModeSelector />,
  },
  {
    path: '/test',
    element: <TestConversation />,
  },
  {
    path: '/install',
    element: <InstallChatbot />,
  },
  {
    path: '/upload',
    element: <UploadView />,
  },
  {
    path: '/installation',
    element: <Installation />,
  },
]);
