export const UploadImage = () => {
  return (
    <svg
      width="282"
      height="206"
      viewBox="0 0 282 206"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M244.049 130.955C223.438 140.091 211.817 206.749 134.378 205.506C105.143 205.031 65.5647 188.513 59.279 163.991C46.0498 112.317 -13.7373 124.961 3.21942 69.2672C8.70113 51.3237 19.9569 45.6593 28.6911 33.3437C44.9535 10.4302 122.136 -24.2143 170.01 25.1212C191.023 46.7556 223.767 33.6361 248.179 41.4567C300.365 58.0845 285.016 112.792 244.049 130.955Z"
        fill="#E7E3FC"
      />
      <path
        d="M183.604 17.3372C168.146 29.3604 157.694 36.2308 142.418 34.0747C105.837 28.9949 85.5179 54.2108 82.6674 75.4799C79.3053 100.659 95.8601 111.44 115.412 121.526C156.634 142.722 140.737 171.483 191.205 167.902C207.468 166.732 214.411 158.29 226.544 153.284C249.092 143.965 277.378 145.536 264.77 117.945C253.88 94.1177 253.843 89.0014 251.979 66.8188C247.886 19.3837 209.989 -3.20098 183.604 17.3372Z"
        stroke="#7D60F5"
        strokeWidth="1.38538"
        strokeMiterlimit="10"
      />
      <path
        d="M165.405 6.77575C161.604 5.7525 158.79 9.73588 158.754 13.4269V169.4C158.754 173.054 161.714 176.014 165.405 176.051L231.003 162.529C235.827 161.47 237.618 159.569 237.654 155.878L236.558 33.1611C236.558 29.5066 235.827 26.583 229.907 25.0481L165.405 6.77575Z"
        fill="#EFEFEF"
        stroke="#7D60F5"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M232.501 41.2009L168.146 27.9717V20.2608C168.183 14.5233 169.827 13.8655 171.8 14.1944L180.279 16.4967V17.4834C180.315 19.8588 180.315 20.1512 183.276 20.809L218.651 29.4335C221.392 30.0913 222.086 29.4335 222.05 27.6794V26.8389L226.654 28.0448C232.867 29.8721 232.501 30.7126 232.501 34.2574V41.2009Z"
        fill="#66D0C2"
      />
      <path
        d="M168.183 28.0448L167.561 72.9218V166.915L233.598 155.586L232.501 41.2009C232.501 41.2009 168.073 28.1179 168.183 28.0448Z"
        fill="white"
      />
      <path
        d="M173.189 34.0382L172.641 83.0447L230.638 80.4134L230.455 43.686C230.491 43.7225 173.116 34.1113 173.189 34.0382Z"
        fill="#E3DCFF"
      />
      <path
        d="M230.674 125.765L230.491 82.46C230.491 82.46 173.116 85.6393 173.226 85.6028L172.677 134.244L230.674 125.765Z"
        fill="#E3DCFF"
      />
      <path
        d="M172.568 136.217V161.799L230.308 151.712L230.491 127.41C230.491 127.41 172.495 136.29 172.568 136.217Z"
        fill="#E3DCFF"
      />
      <path
        d="M175.163 140.493V155.549C175.163 157.084 176.551 158.217 178.05 157.961L224.498 150.543L224.644 131.466C224.644 131.466 186.162 136.729 177.282 138.081C176.04 138.264 175.163 139.287 175.163 140.493Z"
        fill="#A890FB"
      />
      <path
        d="M178.817 41.5298L226.398 48.2906"
        stroke="#A890FB"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M178.817 47.0116L226.398 52.676"
        stroke="#A890FB"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M178.817 91.5961L226.398 88.1244"
        stroke="#A890FB"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M178.817 100.367L226.398 96.8951"
        stroke="#A890FB"
        strokeWidth="5.54153"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M178.817 107.676L224.023 104.387C225.338 104.277 226.435 105.3 226.471 106.616L226.946 121.307C226.983 122.513 226.069 123.536 224.863 123.682L179.986 128.251C178.561 128.397 177.355 127.227 177.465 125.802L178.817 107.676Z"
        fill="#A890FB"
      />
      <path
        d="M188.501 65.3205L226.398 66.9284"
        stroke="#A890FB"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M188.501 73.3603L226.033 73.1411"
        stroke="#A890FB"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M178.817 57.975L198.186 59.3272"
        stroke="#A890FB"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M178.817 52.4933L193.581 53.9551"
        stroke="#A890FB"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M184.774 68.2441H179C178.488 68.2441 178.05 67.8055 178.05 67.2939V62.6893C178.05 62.1411 178.488 61.7026 179.036 61.7391L184.81 62.0315C185.322 62.068 185.724 62.47 185.724 62.9816V67.3304C185.724 67.8055 185.285 68.2441 184.774 68.2441Z"
        fill="#A890FB"
      />
      <path
        d="M184.774 77.0148L179 77.3802C178.488 77.3802 178.05 76.9417 178.05 76.4301V71.8254C178.05 71.2773 178.488 70.8387 179.036 70.8753L184.81 70.8022C185.322 70.8387 185.724 71.2407 185.724 71.7523V76.1012C185.724 76.5762 185.285 77.0148 184.774 77.0148Z"
        fill="#A890FB"
      />
      <path
        d="M227.591 126.49C227.9 128.372 228.301 130.254 229.226 131.92C229.689 132.784 230.399 133.617 231.386 133.771C231.911 133.833 232.466 133.741 232.96 133.525C233.978 133.154 234.965 132.661 235.86 132.013C236.693 131.365 237.495 130.655 238.236 129.884C239.347 128.835 240.365 127.724 241.291 126.521C241.723 125.934 242.093 125.348 242.556 124.793C244.469 122.51 247.554 121.491 249.714 119.455C250.3 118.9 251.504 116.4 251.411 115.598C250.609 115.135 248.973 116.987 248.079 117.264C247.184 117.542 246.382 117.974 245.487 118.221C244.438 118.529 243.296 118.529 242.247 118.252C241.877 118.159 241.445 112.451 241.105 112.605C240.92 112.667 240.766 112.79 240.643 112.945C239.902 113.716 239.501 120.257 238.884 121.121C238.02 122.263 236.848 123.157 235.706 123.991C235.613 124.052 235.459 124.145 235.367 124.083C235.305 124.021 235.243 123.929 235.243 123.836C234.935 122.725 234.595 121.584 234.225 120.504C233.978 119.733 233.608 118.9 232.836 118.653C232.158 118.437 231.448 118.838 230.831 119.208C230.029 119.702 229.226 120.165 228.455 120.658C228.023 120.936 227.499 121.183 227.283 121.676C227.036 122.263 227.252 123.096 227.313 123.713C227.313 124.669 227.437 125.595 227.591 126.49Z"
        fill="#F5BCBA"
      />
      <path
        d="M239.717 113.778C238.853 114.333 238.082 114.765 239.069 116.092C240.704 118.406 240.056 117.418 241.661 119.239C242.278 119.887 242.679 120.689 244.901 119.27C246.104 118.498 251.565 115.104 254.928 113.253C256.163 112.451 256.44 112.019 255.854 111.155C255.823 111.124 255.391 110.538 255.391 110.507L252.923 107.422C252.306 106.681 251.257 106.496 250.424 107.02L239.717 113.778Z"
        fill="#3D3D54"
      />
      <path
        d="M254.589 112.111L243.265 118.899C242.987 119.054 242.648 118.992 242.432 118.745L239.624 115.629C239.439 115.444 239.439 115.166 239.593 114.981C239.624 114.95 239.655 114.919 239.686 114.919L250.979 107.668C251.256 107.514 251.596 107.576 251.812 107.823L254.681 111.371C254.866 111.556 254.866 111.834 254.681 112.019C254.65 112.05 254.62 112.081 254.589 112.111Z"
        fill="#EAEAEA"
      />
      <path
        d="M229.226 121.615C229.072 121.8 228.918 121.985 228.733 122.139C227.992 122.294 226.635 122.324 225.894 122.324C225.462 119.486 225.616 116.709 225.246 113.839C225.37 111.587 225.616 109.365 225.956 107.113C225.801 108.039 226.974 110.075 227.282 111.001L228.64 114.981C229.35 117.079 230.831 119.609 229.226 121.615Z"
        fill="url(#paint0_linear_317_1699)"
      />
      <path
        opacity="0.4"
        d="M213.737 163.701C215.28 164.256 216.946 164.657 218.211 165.706C219.322 166.632 220.001 167.959 220.556 169.316C222.377 173.821 223.055 178.696 222.469 183.54C222.315 184.836 222.099 186.132 222.407 187.397C222.747 188.631 223.888 189.804 225.154 189.649C226.943 186.101 226.974 181.997 226.974 178.017C226.974 175.117 226.974 172.186 226.017 169.44C225.215 167.187 223.827 165.212 222.377 163.33C217.1 156.419 210.744 149.97 202.63 146.854C202.29 146.669 201.92 146.638 201.55 146.73C199.883 147.348 200.161 150.31 200.809 151.513C201.735 153.148 202.753 154.753 203.895 156.265C206.363 159.659 209.788 162.25 213.737 163.701Z"
        fill="url(#paint1_linear_317_1699)"
      />
      <path
        d="M219.291 149.014C222.778 150.186 226.48 150.68 229.843 152.192C230.059 152.253 230.245 152.408 230.337 152.624C230.368 152.778 230.337 152.963 230.275 153.117C227.437 161.541 225.832 170.365 225.524 179.251C227.529 179.622 229.597 179.652 231.602 179.375C231.818 179.375 232.065 179.282 232.25 179.159C232.435 179.005 232.59 178.789 232.651 178.542C235.891 171.229 238.822 163.824 241.414 156.234C241.877 154.907 242.34 153.488 242.155 152.099C241.877 150.063 240.334 148.427 238.884 146.977L227.468 135.592C227.19 135.253 226.82 135.006 226.419 134.821C225.956 134.697 225.462 134.666 224.999 134.759C220.494 135.314 214.354 135.499 206.085 135.129C205.005 135.098 210.312 141.177 210.744 142.349C211.084 143.275 212.225 144.478 212.904 145.188C214.632 147.039 216.946 148.212 219.291 149.014Z"
        fill="#525263"
      />
      <path
        d="M219.291 149.014C222.778 150.186 226.48 150.68 229.843 152.192C230.059 152.253 230.245 152.408 230.337 152.624C230.368 152.778 230.337 152.963 230.275 153.117C227.437 161.541 225.832 170.365 225.524 179.251C227.529 179.622 229.597 179.652 231.602 179.375C231.818 179.375 232.065 179.282 232.25 179.159C232.435 179.005 232.59 178.789 232.651 178.542C235.891 171.229 238.822 163.824 241.414 156.234C241.877 154.907 242.34 153.488 242.155 152.099C241.877 150.063 240.334 148.427 238.884 146.977L227.468 135.592C227.19 135.253 226.82 135.006 226.419 134.821C225.956 134.697 225.462 134.666 224.999 134.759C220.494 135.314 214.354 135.499 206.085 135.129C205.005 135.098 210.312 141.177 210.744 142.349C211.084 143.275 212.225 144.478 212.904 145.188C214.632 147.039 216.946 148.212 219.291 149.014Z"
        fill="#282828"
      />
      <path
        d="M224.938 184.281C224.66 184.713 224.475 185.206 224.382 185.7C224.166 186.595 224.012 187.49 223.919 188.415C223.858 189.125 223.827 189.896 224.228 190.482C224.629 191.069 225.308 191.285 225.925 191.501C227.499 192.149 229.01 192.889 230.492 193.753C231.479 194.308 232.528 194.864 233.639 194.895C234.78 194.926 236.014 194.308 236.261 193.229C236.354 192.704 236.292 192.149 236.138 191.655C235.521 189.464 234.317 187.459 232.651 185.885C232.374 185.607 232.034 185.361 231.664 185.145C231.325 184.959 230.985 184.836 230.615 184.713C229.597 184.342 228.548 184.034 227.468 183.818C226.542 183.664 225.586 183.386 224.938 184.281Z"
        fill="#2E2E3F"
      />
      <path
        d="M224.938 184.281C224.66 184.713 224.475 185.206 224.382 185.7C224.166 186.595 224.012 187.49 223.919 188.415C223.858 189.125 223.827 189.896 224.228 190.482C224.629 191.069 225.308 191.285 225.925 191.501C227.499 192.149 229.01 192.889 230.492 193.753C231.479 194.308 232.528 194.864 233.639 194.895C234.78 194.926 236.014 194.308 236.261 193.229C236.354 192.704 236.292 192.149 236.138 191.655C235.521 189.464 234.317 187.459 232.651 185.885C232.374 185.607 232.034 185.361 231.664 185.145C231.325 184.959 230.985 184.836 230.615 184.713C229.597 184.342 228.548 184.034 227.468 183.818C226.542 183.664 225.586 183.386 224.938 184.281Z"
        fill="#2F2E41"
      />
      <path
        d="M226.079 179.529C225.801 181.041 225.647 182.584 225.616 184.126C225.616 184.805 225.647 185.546 226.141 186.039C226.604 186.41 227.19 186.564 227.776 186.502C228.486 186.471 229.195 186.317 229.843 186.07C229.998 186.039 230.121 185.947 230.214 185.823C230.306 185.7 230.337 185.577 230.368 185.453C230.954 183.262 231.263 180.979 231.293 178.727C231.324 178.357 231.263 178.017 231.077 177.709C230.707 177.184 229.967 177.215 229.35 177.338C228.547 177.493 227.776 177.739 227.036 178.048C226.357 178.295 226.233 178.789 226.079 179.529Z"
        fill="#F5BCBA"
      />
      <path
        d="M233.669 186.749C233.947 187.428 234.163 188.138 234.348 188.847C234.472 189.403 234.533 189.989 234.904 190.452C235.366 191.007 236.138 191.161 236.878 191.161C237.465 191.192 238.051 191.1 238.606 190.884C238.884 190.76 239.131 190.575 239.285 190.297C239.378 190.02 239.439 189.742 239.378 189.464C239.316 187.52 238.822 185.607 237.958 183.849C237.465 182.954 236.878 182.09 236.261 181.257C236.076 180.887 235.737 180.609 235.336 180.486C234.934 180.486 234.502 180.64 234.225 180.918C233.577 181.38 232.188 182.183 232.343 183.077C232.466 184.281 233.237 185.607 233.669 186.749Z"
        fill="#F5BCBA"
      />
      <path
        d="M238.328 188.168C237.896 188.415 237.465 188.662 237.063 188.971C236.415 189.31 235.675 189.403 234.996 189.588C234.811 189.619 234.657 189.711 234.502 189.835C234.41 189.958 234.348 190.112 234.317 190.266C234.194 191.316 234.225 192.365 234.441 193.414C234.78 195.666 235.212 197.888 235.675 200.109C235.86 201.035 236.107 201.991 236.755 202.639C237.403 203.287 238.421 203.534 239.378 203.565C240.272 203.627 241.136 203.441 241.938 203.071C243.234 202.362 244.16 201.127 244.407 199.677C244.654 198.227 244.469 196.746 243.913 195.419C243.512 194.494 243.049 193.63 242.525 192.766C241.846 191.562 241.136 190.39 240.457 189.187C239.964 188.292 239.501 187.644 238.328 188.168Z"
        fill="#2E2E3F"
      />
      <path
        d="M238.328 188.168C237.896 188.415 237.465 188.662 237.063 188.971C236.415 189.31 235.675 189.403 234.996 189.588C234.811 189.619 234.657 189.711 234.502 189.835C234.41 189.958 234.348 190.112 234.317 190.266C234.194 191.316 234.225 192.365 234.441 193.414C234.78 195.666 235.212 197.888 235.675 200.109C235.86 201.035 236.107 201.991 236.755 202.639C237.403 203.287 238.421 203.534 239.378 203.565C240.272 203.627 241.136 203.441 241.938 203.071C243.234 202.362 244.16 201.127 244.407 199.677C244.654 198.227 244.469 196.746 243.913 195.419C243.512 194.494 243.049 193.63 242.525 192.766C241.846 191.562 241.136 190.39 240.457 189.187C239.964 188.292 239.501 187.644 238.328 188.168Z"
        fill="#2F2E41"
      />
      <path
        d="M192.108 113.562C191.83 114.302 191.615 115.074 191.522 115.845C191.213 117.881 190.997 119.918 190.905 121.954C190.751 125.873 190.997 129.791 190.874 133.679C190.751 137.752 190.288 141.948 191.738 145.743C191.954 146.422 192.355 147.07 192.849 147.564C193.373 148.088 194.114 148.273 194.854 148.119C195.502 147.872 195.996 147.348 196.181 146.669C196.366 146.021 196.459 145.342 196.428 144.663L196.49 140.961C196.49 140.899 196.49 140.806 196.551 140.776C196.613 140.745 196.675 140.776 196.736 140.806C197.014 141.053 197.292 141.331 197.539 141.609C197.847 141.855 198.31 141.979 198.557 141.701C198.711 141.516 198.773 141.269 198.711 141.022C198.557 139.418 197.137 138.245 196.304 136.857C195.101 134.851 195.101 132.352 195.41 130.038C195.78 127.138 196.582 124.299 197.353 121.46L198.032 119.085C198.526 117.264 199.02 115.29 198.31 113.562C197.724 112.204 196.52 111.217 195.07 110.908C193.743 110.6 192.633 112.451 192.108 113.562Z"
        fill="#F5BCBA"
      />
      <path
        d="M227.437 135.314C226.943 136.641 224.135 135.098 222.531 135.9C219.476 137.443 215.99 135.87 212.596 135.9C208.986 135.962 206.486 139.541 202.876 139.973C202.63 140.035 202.352 140.035 202.074 139.973C201.581 139.819 201.272 139.325 201.087 138.863C200.778 138.153 200.562 137.381 200.439 136.61C200.007 134.049 200.439 131.334 200.902 128.742C201.303 126.551 201.704 124.361 202.105 122.139C202.568 119.578 203.031 116.987 203.494 114.395C203.771 112.698 204.141 111.001 204.635 109.335C204.728 109.057 204.82 108.779 204.913 108.501C205.808 106.249 206.888 104.058 208.122 101.991C208.893 100.664 209.665 99.3068 210.837 98.2885C212.164 97.1778 213.861 96.6224 215.589 96.3447C217.131 96.067 218.736 96.1596 220.217 96.6224C221.081 96.9309 221.389 97.8257 222.068 98.3811C222.932 99.0291 223.703 99.7696 224.351 100.634C225.03 101.652 225.493 102.793 225.74 103.997C228.146 113.377 224.66 123.466 226.943 132.877C227.313 133.957 227.838 134.265 227.437 135.314Z"
        fill="#2F2E41"
      />
      <path
        d="M224.166 102.392L224.474 104.367L223.302 103.719V108.933L222.006 103.133L223.271 101.281L224.166 102.392Z"
        fill="url(#paint2_linear_317_1699)"
      />
      <path
        d="M214.416 102.269L215.157 102.639L216.298 103.657L213.213 111.896C213.213 111.896 217.687 105.015 217.81 101.528C217.903 99.3685 217.625 97.2087 217.008 95.1414L214.416 102.269Z"
        fill="url(#paint3_linear_317_1699)"
      />
      <path
        d="M204.573 125.904C204.327 127.786 204.327 129.699 204.573 131.581C204.728 132.753 204.975 133.957 204.913 135.129C204.913 135.376 204.851 135.592 204.697 135.777C204.512 135.931 204.296 136.024 204.049 136.024C202.846 136.209 201.642 136.394 200.439 136.61C200.007 134.049 200.439 131.334 200.902 128.742C201.303 126.551 201.704 124.361 202.105 122.139C202.568 119.578 203.031 116.987 203.494 114.395C203.771 112.698 204.141 111.001 204.635 109.335C204.728 109.057 204.82 108.779 204.913 108.501C206.116 107.854 207.752 107.36 208.492 108.409C209.263 109.551 208.245 112.266 207.937 113.407C206.888 117.604 205.098 121.584 204.573 125.904Z"
        fill="url(#paint4_linear_317_1699)"
      />
      <path
        d="M232.188 92.5804C232.219 93.0433 232.219 93.5369 232.158 93.9998C232.065 94.4934 231.664 94.8637 231.17 94.8945C229.967 94.802 229.103 95.9436 228.146 96.6841C226.635 97.8257 224.598 98.0109 222.87 97.1778C222.531 97.0544 222.13 97.2087 222.006 97.5481C221.883 97.8875 221.852 98.2577 221.945 98.5971L222.377 102.793C222.469 103.596 222.469 104.583 221.79 105.046C221.112 105.478 220.278 105.107 219.723 104.614C218.828 103.75 218.18 102.67 217.841 101.467C216.298 96.5298 214.879 84.9285 218.335 81.1025C218.983 80.3928 219.723 79.7757 220.556 79.3129C221.42 78.8501 222.346 78.5415 223.333 78.3873C224.197 78.1713 225.092 78.1404 225.956 78.233C227.128 78.449 233.978 82.275 234.78 83.2006C235.366 83.8486 234.503 85.7924 233.947 87.7362C233.484 88.9396 232.219 91.2845 232.188 92.5804Z"
        fill="#F5BCBA"
      />
      <path
        d="M223.055 107.668C223.919 112.111 224.197 116.647 223.827 121.183C223.518 124.947 222.809 128.68 222.839 132.445C222.901 133.494 222.87 134.574 222.747 135.623C222.593 136.672 215.558 136.271 214.539 136.271C212.812 136.24 215.002 138.585 213.706 137.443C211.67 135.684 210.775 132.908 210.528 130.223C210.158 126.397 210.96 122.571 211.793 118.838C212.38 116.246 213.028 113.654 214.077 111.217C215.403 108.193 217.471 104.367 218.149 101.127C218.242 100.664 216.607 98.9365 217.316 98.9674C217.779 98.9674 217.563 99.677 217.625 100.016C217.779 100.541 218.057 101.035 218.427 101.467C218.952 102.084 219.692 102.516 220.494 102.701C221.019 102.793 221.605 102.763 221.975 103.133C222.438 103.565 222.346 104.46 222.5 105.046C222.685 105.91 222.87 106.774 223.055 107.668Z"
        fill="#89DFCF"
      />
      <path
        d="M206.517 97.7023C204.789 98.8131 198.68 105.817 197.662 106.897C196.242 108.378 195.039 108.872 192.633 111.834C192.54 112.914 192.725 113.994 193.188 114.95C193.558 115.937 194.083 116.863 194.792 117.696C195.656 118.653 196.829 119.331 198.094 119.609C198.495 119.702 198.896 119.733 199.328 119.671C199.914 119.517 200.439 119.208 200.871 118.807C201.858 118.036 202.815 117.233 203.709 116.339C204.604 115.382 205.376 114.333 206.27 113.346C207.042 112.513 207.844 111.772 208.615 110.939C209.541 109.952 210.343 108.903 211.176 107.823C212.349 106.372 213.398 104.861 214.354 103.287C215.156 101.899 215.804 100.418 216.236 98.8748C216.545 97.8566 217.008 96.5915 215.99 95.851C213.768 94.3392 210.127 95.3882 206.517 97.7023Z"
        fill="#2F2E41"
      />
      <path
        d="M200.716 146.885C201.148 148.489 201.92 149.97 202.969 151.235C204.11 152.593 205.684 153.549 207.196 154.444L213.552 158.301C214.478 158.826 215.372 159.443 216.205 160.091C218.273 161.818 219.661 164.194 221.019 166.539L230.275 182.676C230.738 183.509 231.386 184.435 232.342 184.404C232.867 184.342 233.33 184.126 233.731 183.787C234.903 182.954 236.199 181.997 236.539 180.609C236.786 179.622 236.539 178.603 236.23 177.616C234.811 172.803 232.589 168.298 230.337 163.793C226.789 156.666 223.179 149.569 219.507 142.473C218.52 140.59 214.416 136.178 213.398 136.147C209.479 135.962 200.747 135.685 200.439 136.857C199.636 140.158 199.698 143.614 200.716 146.885Z"
        fill="#525263"
      />
      <path
        d="M200.716 146.885C201.148 148.489 201.92 149.97 202.969 151.235C204.11 152.593 205.684 153.549 207.196 154.444L213.552 158.301C214.478 158.826 215.372 159.443 216.205 160.091C218.273 161.818 219.661 164.194 221.019 166.539L230.275 182.676C230.738 183.509 231.386 184.435 232.342 184.404C232.867 184.342 233.33 184.126 233.731 183.787C234.903 182.954 236.199 181.997 236.539 180.609C236.786 179.622 236.539 178.603 236.23 177.616C234.811 172.803 232.589 168.298 230.337 163.793C226.789 156.666 223.179 149.569 219.507 142.473C218.52 140.59 214.91 136.24 213.644 136.178C209.51 135.993 200.747 135.715 200.439 136.857C199.636 140.158 199.698 143.614 200.716 146.885Z"
        fill="#303030"
      />
      <path
        d="M217.501 94.1232L215.372 94.9562L214.107 102.115L218.365 101.929L217.501 94.1232Z"
        fill="#89DFCF"
      />
      <path
        d="M234.626 118.437C234.503 118.684 234.379 118.869 234.194 119.054C232.744 120.597 230.831 121.676 228.764 122.139C228.023 122.294 227.005 122.324 226.264 122.324C225.832 119.486 225.647 116.678 225.308 113.839C225.03 111.618 224.845 109.365 224.722 107.113C224.66 105.694 221.605 100.819 222.222 99.4919C222.901 98.0417 227.869 102.978 228.424 103.935C231.51 109.119 232.62 113.13 234.749 117.604C234.811 117.881 234.78 118.19 234.626 118.437Z"
        fill="#2F2E41"
      />
      <path
        d="M221.945 101.744C221.945 102.053 221.945 102.392 221.945 102.701C221.945 102.855 221.945 102.978 221.975 103.133C222.006 103.287 222.037 103.41 222.068 103.565L222.593 105.663L222.808 102.269C223.426 102.577 224.074 102.855 224.722 103.102C224.66 102.608 224.536 102.084 224.351 101.621C223.981 100.634 223.611 99.6462 223.21 98.6897C223.117 98.4428 222.963 98.196 222.808 97.98C222.623 97.764 222.346 97.6406 222.068 97.6715C221.698 97.8258 221.914 99.2759 221.945 99.5845V101.744Z"
        fill="#89DFCF"
      />
      <path
        d="M238.483 83.5709C238.76 81.3185 237.619 79.1586 235.613 78.1096C234.749 77.6776 233.762 77.4925 232.836 77.2456C230.646 76.6594 228.517 75.6721 226.264 75.4561C224.012 75.2401 221.42 76.0423 220.402 78.0787C220.217 78.449 220.032 78.9118 219.631 79.0044C219.415 79.0352 219.199 79.0044 218.983 78.9427C217.471 78.665 216.113 79.9609 215.434 81.3493C214.324 83.5709 214.293 86.1627 214.509 88.6002C214.725 90.8834 215.311 93.3518 217.131 94.7094C217.316 94.8328 217.563 94.9871 217.748 94.8328C217.841 94.7403 217.872 94.6477 217.903 94.5243C218.088 93.6604 218.149 92.7656 218.119 91.9016C218.088 91.2537 217.964 90.544 218.15 89.8961C218.335 89.2481 218.859 88.631 219.538 88.6619C220.155 88.6619 220.649 89.2481 220.803 89.8344C220.926 90.4515 220.896 91.0686 220.741 91.6857C220.834 91.9325 221.173 91.624 221.235 91.3771C221.698 89.3098 221.79 86.9957 223.21 85.453C223.487 85.7615 223.703 86.1318 223.827 86.5329C224.259 86.0701 224.907 85.9158 225.524 86.101C225.925 86.2861 226.326 86.5329 226.665 86.8415C230.183 89.6184 237.773 88.7853 238.483 83.5709Z"
        fill="#FF681C"
      />
      <defs>
        <linearGradient
          id="paint0_linear_317_1699"
          x1="218.302"
          y1="114.845"
          x2="234.07"
          y2="114.602"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.01" />
          <stop offset="0.13" stopOpacity="0.69" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_317_1699"
          x1="234.032"
          y1="146.469"
          x2="199.595"
          y2="182.443"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.01" />
          <stop offset="0.13" stopOpacity="0.69" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_317_1699"
          x1="217.425"
          y1="100.23"
          x2="228.079"
          y2="109.093"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.01" />
          <stop offset="0.13" stopOpacity="0.69" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_317_1699"
          x1="224.44"
          y1="99.8347"
          x2="204.749"
          y2="107.634"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.01" />
          <stop offset="0.13" stopOpacity="0.69" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_317_1699"
          x1="191.227"
          y1="122.654"
          x2="215.624"
          y2="121.808"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.01" />
          <stop offset="0.13" stopOpacity="0.69" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
