export const IconMessage = () => {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.8335 4.58176V18.3005L19.6147 17.0818H4.16683V4.58176H20.8335ZM20.8335 2.49843H4.16683C3.021 2.49843 2.0835 3.43593 2.0835 4.58176V17.0818C2.0835 18.2276 3.021 19.1651 4.16683 19.1651H18.7502L22.9168 23.3318V4.58176C22.9168 3.43593 21.9793 2.49843 20.8335 2.49843ZM18.7502 12.9151H6.25016V14.9984H18.7502V12.9151ZM18.7502 9.79009H6.25016V11.8734H18.7502V9.79009ZM18.7502 6.66509H6.25016V8.74843H18.7502V6.66509Z"
        fill="#9378F8"
      />
    </svg>
  );
};
