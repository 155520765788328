export const MessageLoader = () => {
  return (
    <div className="w-8 h-2 flex bg-primary p-2 rounded justify-between items-center">
      {Array.from({ length: 3 }).map((_, idx) => (
        <div
          key={idx}
          className={`bg-primary-25 h-1 w-1 rounded-full animate-pulse delay-${idx}00`}
        ></div>
      ))}
    </div>
  );
};
