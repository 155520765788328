export const IconEdit = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.97998 11.6377H4.01793L10.0285 5.62706L7.99059 3.5891L1.97998 9.59971V11.6377ZM3.06689 10.0508L7.99059 5.12708L8.49056 5.62706L3.56687 10.5508H3.06689V10.0508Z"
        fill="white"
      />
      <path
        d="M10.3329 2.01309C10.1209 1.80114 9.77855 1.80114 9.5666 2.01309L8.57208 3.00761L10.61 5.04556L11.6046 4.05104C11.8165 3.83909 11.8165 3.49672 11.6046 3.28477L10.3329 2.01309Z"
        fill="white"
      />
    </svg>
  );
};
