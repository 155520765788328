import { Button } from 'cliengo-ds';

import { InstallationCard } from './InstallationCard';
export const Installation = () => {
  return (
    <div className="min-h-screen flex-center">
      <div className=" bg-white w-[904px] rounded-lg flex-col flex gap-8 p-8">
        <h1 className="font-bold !text-2xl flex items-center text-center justify-center">
          Instala el chatbot en tu sitio web
        </h1>
        <div className="flex-center">
          <InstallationCard />
        </div>
        <div className="flex-center gap-5">
          <Button mode="outline" variant="secondary">
            Lo instalaré más tarde
          </Button>
          <Button className="w-52" variant="primary">
            Ya lo instalé
          </Button>
        </div>
      </div>
    </div>
  );
};
