import { Button } from 'cliengo-ds';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import confetti from 'canvas-confetti';

import { IconEditMessageExample } from '../../components/Widget/IconEditMessageExample';
import { IconMessage } from '../../components/Widget/IconMessage';

import { WidgetContainer } from '@/components/Widget/WidgetContainer';
import { WidgetMessageExample } from '@/components/Widget/WidgetMessageExample';

const confettis = [
  {
    particleCount: 1000,
    angle: 80,
    spread: 1000,
    origin: {
      x: 0,
      y: 0.3,
    },
    zIndex: 0,
  },

  {
    particleCount: 1000,
    angle: 80,
    spread: 1000,
    origin: {
      x: 1,
      y: 0.3,
    },
    zIndex: 0,
  },
  {
    particleCount: 1000,
    angle: 80,
    spread: 1000,
    origin: {
      x: 0.5,
      y: 1,
    },
    zIndex: 0,
  },
];

export const TestConversation = () => {
  const navigate = useNavigate();

  useEffect(() => {
    confettis.forEach((c) => {
      void confetti(c);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="relative z-10 min-h-screen flex-center">
        <div className="w-[904px] h-[611px] bg-white rounded-md shadow-md">
          <div className="flex-center flex-col gap-8">
            <h1 className="!font-bold text-2xl mt-10">
              Interactúa con el chatbot generado por{' '}
              <span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-primary to-secondary">
                IA
              </span>
            </h1>
            <div className="flex gap-6">
              <WidgetMessageExample
                Icon={IconMessage}
                message={`Simula la conversación que tendrán tus visitantes con el bot.`}
              />
              <WidgetContainer />
              <div className="flex flex-col justify-end ">
                <WidgetMessageExample
                  Icon={IconEditMessageExample}
                  message={
                    'Puedes editar las preguntas de tu chatbot desde aquí'
                  }
                />
                <div className="h-1/2 mt-8" />
              </div>
            </div>

            <Button
              className="px-8"
              variant="primary"
              onClick={() => {
                navigate('/installation');
              }}
            >
              Instala tu chatbot
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
