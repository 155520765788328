export const IconOnline = () => {
  return (
    <svg
      width="8"
      height="7"
      viewBox="0 0 8 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="3.88678" cy="3.51306" r="3.22174" fill="#1ABC9C" />
    </svg>
  );
};
