import { Spinner } from 'cliengo-ds';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { UploadImage } from './icon';

import { generateNewTemplate } from '@/requests/generateNewTemplate';
import {
  initMessageQueue,
  templateMessages,
} from '@/components/Widget/utils/MessageProccesor';

export const UploadView = () => {
  const navigate = useNavigate();

  useEffect(() => {
    generateNewTemplate()
      .then((messages) => {
        templateMessages.value = messages;
        initMessageQueue(messages[0]);
        setTimeout(() => {
          navigate('/test');
        }, 1000);
      })
      .catch(() => {
        navigate('/error');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="min-h-screen flex-center flex-col">
      <div className="bg-white max-w-[684px] w-full shadow-lg flex-column justify-center items-center h-[462px] ">
        <div className="flex-center mt-14">
          <UploadImage />
        </div>
        <p className="text-primary-400 mb-2 mt-9 font-semibold text-2xl text-center">
          Estamos personalizando la conversación <br />
          para el chatbot de tu negocio
        </p>
        <Spinner />
      </div>
    </div>
  );
};
