import { Button } from 'cliengo-ds';
import { Link } from 'react-router-dom';

import { CircleIcon } from './icon';

export const MainView = () => {
  return (
    <div className="min-h-screen flex-center">
      <div className="p-4 flex-between items-center gap-4 rounded-md bg-white shadow-lg">
        <CircleIcon />
        <p className="!font-bold text-base ">
          ¡Crea una conversación increíble para el chatbot de tu empresa!
        </p>
        <Link to="/mode">
          <Button className="text-sm" variant="primary">
            Comenzar
          </Button>
        </Link>
      </div>
    </div>
  );
};
