export const IconCopyCheck = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7988 10.1112C19.7988 15.4615 15.4616 19.7987 10.1113 19.7987C4.76105 19.7987 0.423828 15.4615 0.423828 10.1112C0.423828 4.76092 4.76105 0.423691 10.1113 0.423691C15.4616 0.423691 19.7988 4.76092 19.7988 10.1112ZM8.99078 15.2406L16.1783 8.05314C16.4223 7.80908 16.4223 7.41334 16.1783 7.16928L15.2944 6.28541C15.0504 6.04131 14.6546 6.04131 14.4105 6.28541L8.54883 12.1471L5.81215 9.41037C5.56809 9.16631 5.17234 9.16631 4.92824 9.41037L4.04437 10.2942C3.80031 10.5383 3.80031 10.934 4.04437 11.1781L8.10688 15.2406C8.35098 15.4847 8.74668 15.4847 8.99078 15.2406V15.2406Z"
        fill="white"
      />
    </svg>
  );
};
