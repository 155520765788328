import { useState } from 'react';

import { SendIcon } from './SendIcon';
import { MessageProcessor } from './utils/MessageProccesor';

export const WidgetInputMessage = () => {
  const [value, setValue] = useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (value.trim() === '') {
      return;
    }
    new MessageProcessor({ response: value.trim() }).handleNewMessage('text');
    setValue('');
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex h-8">
        <input
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          className="px-3 bg-white w-full text-xs text-gray-900 focus:outline-none"
          placeholder="Escribe un mensaje..."
          value={value}
          onChange={handleOnChange}
        />
        <button type="submit" className="bg-white px-2 flex items-center">
          <SendIcon />
        </button>
      </div>
      <p className="mt-1 text-gray-500 flex-center pb-2 !text-xs">
        Usamos Cliengo
      </p>
    </form>
  );
};
