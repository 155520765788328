import { classNames, useOutsideClick } from 'cliengo-ds';
import { useRef, useState } from 'react';

import { IconEdit } from './IconEditMessage';
import {
  AppMessage,
  MessageProcessor,
  updateQuestion,
} from './utils/MessageProccesor';

interface WidgetMessageProps {
  message: AppMessage;
}

export const WidgetMessage = ({ message }: WidgetMessageProps) => {
  const { response, sender, response_options } = message;
  const inputRef = useRef(null);

  useOutsideClick({
    ref: inputRef,
    handler: () => {
      setIsEditable(false);
    },
  });

  const [isEditable, setIsEditable] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    updateQuestion({
      ...message,
      response: e.target.value,
    });
  };

  const handleOnClick = () => sender === 'bot' && setIsEditable(true);

  const handleQuickReplyClick = (option: string) => {
    new MessageProcessor({
      response: option,
    }).handleNewMessage('quickreply');
  };

  return (
    <>
      <div
        className={`mx-3 ${
          sender === 'user' ? 'flex justify-end' : 'flex justify-start'
        }`}
      >
        {isEditable ? (
          <textarea
            ref={inputRef}
            className="p-2 text-xs w-full block bg-primary text-white rounded-ss-none rounded-2xl focus:outline-none"
            value={response}
            onChange={handleChange}
          />
        ) : (
          <button
            onClick={() => {
              sender === 'bot' && handleOnClick();
            }}
            className={classNames(
              'flex gap-2 items-center rounded-2xl text-xs justify-between px-3 py-2',
              {
                'text-start bg-primary rounded-ss-none text-white':
                  sender === 'bot',
                'bg-gray-400 text-end rounded-se-none text-gray-900':
                  sender === 'user',
              }
            )}
          >
            <span>{response}</span>
            {sender == 'bot' && (
              <span className="w-4 mb-auto">
                <IconEdit />
              </span>
            )}
          </button>
        )}
      </div>
      {response_options && (
        <div className="flex flex-wrap gap-2 px-4">
          {response_options.map((option) => (
            <button
              onClick={() => handleQuickReplyClick(option)}
              className="border border-info px-4 py-1 text-xs text-info rounded-full"
              key={option}
            >
              {option}
            </button>
          ))}
        </div>
      )}
    </>
  );
};
