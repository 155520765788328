export const IconEditMessageExample = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.12354 21.8763H7.02978L18.5506 10.3555L14.6444 6.44922L3.12354 17.9701V21.8763ZM5.20687 18.8346L14.6444 9.39714L15.6027 10.3555L6.1652 19.793H5.20687V18.8346Z"
        fill="#9378F8"
      />
      <path
        d="M19.134 3.42839C18.7277 3.02214 18.0715 3.02214 17.6652 3.42839L15.759 5.33464L19.6652 9.24089L21.5714 7.33464C21.9777 6.92839 21.9777 6.27214 21.5714 5.86589L19.134 3.42839Z"
        fill="#9378F8"
      />
    </svg>
  );
};
