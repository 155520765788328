import { useEffect, useRef } from 'react';

import { IconOnline } from './IconOnline';
import { MessageLoader } from './MessageLoader';
import { WidgetInputMessage } from './WidgetInputMessage';
import { WidgetMessage } from './WidgetMessage';
import { isLoading, messagesQueue } from './utils/MessageProccesor';

export const WidgetContainer = () => {
  const widgetBodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const widgetBody = widgetBodyRef.current;

    if (widgetBody) {
      widgetBody.scrollTop = widgetBody.scrollHeight;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesQueue.value]);

  return (
    <div className="w-64 bg-gray-100 flex flex-col rounded-md border-gray-400 border relative">
      <header className="bg-white rounded-t pb-2 w-63 flex-col">
        <h1 className="!text-sm px-3 pt-2 !font-bold text-gray-800">Pilar</h1>
        <div className="flex items-center">
          <div className="pl-3">
            <IconOnline />
          </div>
          <p className="px-1 text-xs">Disponible ahora</p>
        </div>
      </header>

      <div
        ref={widgetBodyRef}
        className="h-72 flex flex-col gap-2 py-4 overflow-y-auto"
      >
        {messagesQueue.value.map((chatMessage, i) => (
          <WidgetMessage message={chatMessage} key={i} />
        ))}
      </div>
      {isLoading.value && (
        <div className="absolute bottom-[70px] left-2">
          <MessageLoader />
        </div>
      )}
      <div className="items-end">
        <WidgetInputMessage />
      </div>
    </div>
  );
};
