import { Button, classNames } from 'cliengo-ds';

import { CardProps } from './CardProps';

export const Card = (props: CardProps) => {
  const {
    text,
    textButton,
    type,
    title,
    onClick,
    className = '',
    value,
    onChange,
    disabled,
    ...rest
  } = props;

  return (
    <div className={`flex flex-col gap-4 max-w-xs ${className}`} {...rest}>
      <header className={`${disabled ? 'opacity-50' : ''}`}>
        <h3 className={`!font-semibold !text-lg !text-black`}>{title}</h3>

        <p className={`!font-medium !text-sm flex items-center !text-black`}>
          {text}
        </p>
      </header>

      <div className="mb-4">
        {type === 'web' ? (
          <input
            value={value}
            className={classNames(
              'bg-gray-200 w-full rounded-md h-10 p-3 placeholder:text-gray-600 focus:outline-gray-600 text-gray-700',
              disabled ? 'disabled:opacity-50' : ''
            )}
            type="text"
            placeholder="www.tusitioweb.com"
            onChange={onChange}
            name="scrapUrl"
            disabled={disabled}
          />
        ) : (
          <textarea
            className={classNames(
              'bg-gray-200 w-full rounded-md h-20 p-3 placeholder:text-gray-600 disabled:opacity-70 text-gray-700 focus:outline-gray-600',
              disabled ? 'disabled:opacity-50 disabled:cursor-not-allowed' : ''
            )}
            disabled={disabled}
            name="businessInfo"
            onChange={onChange}
            placeholder="  Explícanos que hace tu negocio"
          />
        )}
      </div>

      <Button
        disabled={disabled}
        className="mt-auto mx-auto block px-8"
        onClick={onClick as () => undefined}
        variant="primary"
      >
        {textButton}
      </Button>
    </div>
  );
};
