import { RouterProvider } from 'react-router-dom';

import './app.css';
import 'cliengo-ds/dist/style.css';
import { router } from './router';

export const App = () => {
  return (
    <div className="bg-pattern">
      <RouterProvider router={router} />
    </div>
  );
};
