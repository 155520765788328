export const IconCopy = () => {
  return (
    <svg
      width="13"
      height="15"
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66683 0.166687H1.66683C0.933496 0.166687 0.333496 0.766687 0.333496 1.50002V10.8334H1.66683V1.50002H9.66683V0.166687ZM9.00016 2.83335H4.3335C3.60016 2.83335 3.00683 3.43335 3.00683 4.16669L3.00016 13.5C3.00016 14.2334 3.5935 14.8334 4.32683 14.8334H11.6668C12.4002 14.8334 13.0002 14.2334 13.0002 13.5V6.83335L9.00016 2.83335ZM4.3335 13.5V4.16669H8.3335V7.50002H11.6668V13.5H4.3335Z"
        fill="white"
      />
    </svg>
  );
};
