/* eslint-disable react-func/max-lines-per-function */
import { signal } from '@preact/signals-react';

export interface Message {
  internal_name: string;
  label: string;
  parent?: null | string;
  response: string;
  response_options: string[];
}

export interface AppMessage extends Message {
  sender: 'bot' | 'user';
  isEditable: boolean;
}
export const templateMessages = signal<Message[]>([]);
export const messagesQueue = signal<AppMessage[]>([]);
export const isLoading = signal<boolean>(false);

interface MessageProcessorArgs {
  response: string;
}

const templateMessageAdapter = (response: Message): AppMessage => {
  return {
    ...response,
    sender: 'bot',
    isEditable: true,
  };
};

export const initMessageQueue = (response: Message) => {
  messagesQueue.value = [templateMessageAdapter(response)];
};

export const updateQuestion = (question: AppMessage) => {
  messagesQueue.value = messagesQueue.value.map((message) => {
    if (message.internal_name === question.internal_name) {
      return {
        ...message,
        ...question,
      };
    }
    return message;
  });

  // TODO: remove this console after demo
  // eslint-disable-next-line no-console
  console.log(messagesQueue.value);
};

export class MessageProcessor {
  response: string;

  constructor(args: MessageProcessorArgs) {
    this.response = args.response;
  }

  private formatMessage(response: string): AppMessage {
    return {
      internal_name: '',
      label: '',
      parent: null,
      response,
      response_options: [],
      sender: 'user',
      isEditable: false,
    };
  }

  private findLastBotMessage(): [AppMessage, number] {
    let lastBotMessage = null;
    let lastIndex = 0;

    for (let i = messagesQueue.value.length - 1; i >= 0; i--) {
      const message = messagesQueue.value[i];

      if (message.sender === 'bot') {
        lastBotMessage = message;
        lastIndex = i <= 1 ? 1 : i;
        break;
      }
    }

    return [lastBotMessage as AppMessage, lastIndex];
  }

  private pushMessage(message: AppMessage) {
    messagesQueue.value = [...messagesQueue.value, message];
  }

  private sendBotMessage() {
    const [lastMessage] = this.findLastBotMessage();

    try {
      const lastIndexOnTemplate = templateMessages.value.findIndex(
        (m) => m.internal_name === lastMessage.internal_name
      );

      this.sendNextBaseMessage(lastIndexOnTemplate);
    } catch (error) {
      return;
    }
  }

  private sendNextBaseMessage(lastIndex = 1) {
    let nextMessage;

    for (let i = lastIndex; i < templateMessages.value.length; i++) {
      const m = templateMessages.value[i];

      if (
        !m.parent &&
        m.internal_name !== templateMessages.value[lastIndex].internal_name
      ) {
        // eslint-disable-next-line no-console
        console.log({
          f: m,
          last: templateMessages.value[lastIndex],
        });
        nextMessage = m;
        break;
      }
    }

    if (nextMessage) {
      this.pushMessage({
        ...nextMessage,
        sender: 'bot',
        isEditable: true,
      });
    }
  }

  private handleQuickReply(quickreply: string) {
    const [lastBotMessage, lastIndex] = this.findLastBotMessage();

    try {
      if (['Si', 'No', 'Sí'].includes(quickreply)) {
        this.sendNextBaseMessage(lastIndex);
        return;
      }
      const message = templateMessages.value.find(
        (m) =>
          m.label === quickreply &&
          lastBotMessage.response_options.includes(m.label)
      );

      if (message) {
        this.pushMessage({
          ...message,
          sender: 'bot',
          isEditable: true,
        });
      } else {
        this.sendNextBaseMessage(lastIndex);
        console.error('No se econtro la pregunta', {
          lastBotMessage,
          quickreply,
        });
      }
    } catch (error) {
      return;
    }
  }

  private sendUserMessage(message: string) {
    messagesQueue.value = [...messagesQueue.value, this.formatMessage(message)];
  }

  handleNewMessage(inputType: 'text' | 'quickreply') {
    // pusheando el mensaje del visitor
    this.sendUserMessage(this.response);

    // pusheando el mensaje del bot
    isLoading.value = true;

    setTimeout(() => {
      if (inputType === 'text') {
        this.sendBotMessage();
      }
      if (inputType === 'quickreply') {
        this.handleQuickReply(this.response);
      }
      isLoading.value = false;
    }, 700);
  }
}
